import React from "react"

export const Year = ({ from }) => {
  const ageDifMs = Date.now() - from
  const ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970) + 1
}

Year.defaultProps = {
  from: new Date(2019, 11, 15),
}
