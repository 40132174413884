import PropTypes from "prop-types"
import React from "react"
import { createUseStyles } from "react-jss"
import classNames from "classnames"
import { WithLink } from "./withLink"
import LinkedInIcon from "../../images/icons/linkedin-brands.svg"
import { MyA } from "../mdComponents/myA"

const useStyles = createUseStyles(theme => ({
  setHeight: {
    height: "100%",
  },
  root: {
    padding: {
      left: 64,
      right: 64,
    },
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  name: {
    fontWeight: 700,
    margin: 0,
  },
  firma: {
    margin: {
      top: 8,
    },
    color: theme.palette.primary.main,
  },
  text: {
    margin: {
      top: 24,
    },
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      fontSize: "inherit",
      lineHeight: "inherit",
    },
  },
  smallText: {
    fontSize: 20,
    lineHeight: "30px",
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      fontSize: "inherit",
      lineHeight: "inherit",
    },
  },
  quote: {
    fontSize: 128,
    fontWeight: 700,
    height: 64,
  },

  icon: {
    display: "inline-box",
    height: 19,
    width: 19,
    margin: {
      top: 8,
      left: 4,
      right: 4,
    },
  },
}))

export const Testimonial = ({
  firma,
  url,
  text,
  icon,
  smallText,
  name,
  className,
  verticalCenter,
}) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(
        classes.root,
        { [classes.setHeight]: verticalCenter },
        className
      )}
    >
      <WithLink url={url}>
        <p className={classes.name}>{name}</p>
      </WithLink>
      {firma && (
        <WithLink url={url}>
          <p className={classes.firma}>{firma}</p>
        </WithLink>
      )}

      {icon && (
        <MyA href={url} target={"_blank"} rel={"noreferrer noopener"}>
          <LinkedInIcon className={classes.icon} />
        </MyA>
      )}

      {!(firma || icon) && <p></p>}

      <p
        className={classNames(classes.text, { [classes.smallText]: smallText })}
      >
        {text}
      </p>
      <span className={classes.quote}>{"\u201D"}</span>
    </div>
  )
}

Testimonial.propTypes = {
  name: PropTypes.string,
  firma: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  smallText: PropTypes.bool,
  verticalCenter: PropTypes.bool,
}
