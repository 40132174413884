import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import classNames from "classnames"
import { FancyButton } from "./fancyButton"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const useStyles = createUseStyles(theme => ({
  input: {
    width: "90%",
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      width: "100%",
    },
    padding: [10, 15],
    border: [1, "solid"],
    borderRadius: 5,
    margin: {
      bottom: 20,
    },
    outline: "none",
  },
  inputField: {
    height: 150,
    border: [1, "solid", theme.palette.primary.dark],
  },
  message: {
    width: "90%",
    margin: {
      bottom: 20,
    },
  },
  error: {
    color: theme.palette.error,
  },
  ok: {
    color: theme.palette.ok,
  },
}))

class RequestState {}

class RequestNotSent extends RequestState {}

class RequestSent extends RequestState {}

class RequestOk extends RequestState {}

class RequestError extends RequestState {
  constructor(error) {
    super()
    this.error = error
  }
}

const category = "ContactForm"
const action = "send"

export const ContactForm = () => {
  const [requestState, setRequestState] = useState(new RequestNotSent())
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const classes = useStyles()

  const disabled = requestState instanceof RequestSent

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()

        trackCustomEvent({
          category,
          action,
        })

        if (!name || !email || !message) {
          trackCustomEvent({
            category,
            action,
            label: "failure validation",
          })
          return setRequestState(
            new RequestError("Bitte füllen sie alle Felder aus")
          )
        }

        setRequestState(new RequestSent())
        try {
          const response = await fetch(process.env.GATSBY_MAIL_ME_URL, {
            method: "POST",
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name,
              email,
              message,
            }),
          })

          const json = await response.json()

          if (response.status !== 200 || !json.success) {
            trackCustomEvent({
              category,
              action,
              label: "failure server",
            })
            return setRequestState(new RequestError(json.msg))
          }

          setName("")
          setEmail("")
          setMessage("")
          trackCustomEvent({
            category,
            action,
            label: "success",
          })
          setRequestState(new RequestOk())
        } catch (e) {
          console.log(e)
          return setRequestState(
            new RequestError("Die Nachricht konnte nicht versendet werden.")
          )
        }
      }}
    >
      <input
        disabled={disabled}
        className={classes.input}
        type="text"
        placeholder="Ihr Name"
        value={name}
        onChange={v => setName(v.target.value)}
      />
      <input
        disabled={disabled}
        className={classes.input}
        type="email"
        placeholder="Ihre E-Mail-Adresse"
        value={email}
        onChange={v => setEmail(v.target.value)}
      />
      <textarea
        disabled={disabled}
        className={classNames(classes.input, classes.inputField)}
        placeholder="Ihre Nachricht"
        value={message}
        onChange={v => setMessage(v.target.value)}
      />
      <br />
      {requestState instanceof RequestError && (
        <div className={classNames(classes.message, classes.error)}>
          {requestState.error}
        </div>
      )}
      {requestState instanceof RequestOk && (
        <div className={classNames(classes.message, classes.ok)}>
          Nachricht wurde erfolgreich gesendet.
        </div>
      )}
      <FancyButton>Absenden</FancyButton>
    </form>
  )
}
