import React from "react"
import { createUseStyles } from "react-jss"
import classNames from "classnames"
import PropTypes from "prop-types"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    //alignItems: 'center',
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      flexDirection: "column",
    },
  },
  content: {
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      display: "block",
      width: ["100%", "!important"],
    },
  },
  contentLeft: {
    width: props =>
      props.space
        ? `calc(${props.width}% - ${props.space / 2}px)`
        : props.width + "%",
  },
  contentRight: {
    width: props =>
      props.space
        ? `calc(${100 - props.width}% - ${props.space / 2}px)`
        : 100 - props.width + "%",
  },
  spacer: {
    display: "inline-block",
    width: props => props.space,
  },
}))

export const Split = props => {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <div className={classNames(classes.contentLeft, classes.content)}>
        {props.children[0]}
      </div>
      {props.space && <div className={classes.spacer} />}
      <div className={classNames(classes.contentRight, classes.content)}>
        {props.children[1]}
      </div>
    </div>
  )
}

Split.propTypes = {
  width: PropTypes.number,
  space: PropTypes.number,
}

Split.defaultProps = {
  width: 50,
}
