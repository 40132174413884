import React from "react"

const defaultGlobalState = {
  selectedOutput: "KMU", // or 'SELF'
  setSelectedOutput: _ => {},
}

export const globalStateContext = React.createContext(defaultGlobalState)

export const State = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (state, newValue) => newValue,
    "SELF"
  )

  return (
    <globalStateContext.Provider
      value={{ selectedOutput: state, setSelectedOutput: dispatch }}
    >
      {children}
    </globalStateContext.Provider>
  )
}
