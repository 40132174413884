import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BgImage from "gatsby-background-image"
import { createUseStyles } from "react-jss"
import classNames from "classnames"
import PropTypes from "prop-types"

const useStyles = createUseStyles(theme => ({
  content: {
    color: theme.palette.white,
  },
  darken: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  },
}))

export const BackgroundImage = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid
            originalName
          }
        }
      }
    }
  `)

  const image = data.allImageSharp.nodes.find(
    i => i.fluid.originalName === props.image
  )

  return (
    <BgImage
      style={{
        backgroundPosition: props.position,
      }}
      fluid={image.fluid}
    >
      <div
        className={classNames(classes.content, {
          [classes.darken]: props.darken,
        })}
      >
        {props.children}
      </div>
    </BgImage>
  )
}

BackgroundImage.propTypes = {
  image: PropTypes.string.isRequired,
  position: PropTypes.string,
  darken: PropTypes.bool,
}

BackgroundImage.defaultProps = {
  position: "center",
  darken: true,
}
