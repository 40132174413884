import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  ul: {
    padding: {
      left: 0,
    },
  },
  li: {
    borderTop: {
      width: 1,
      color: theme.palette.primary.main,
      style: "solid",
    },
    listStyleType: "none",
  },
}))

export const FancyList = props => {
  const classes = useStyles()

  return (
    <ul className={classes.ul}>
      {props.children.map((e, i) => (
        <li key={i} className={classes.li}>
          {e}
        </li>
      ))}
    </ul>
  )
}
