import React from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  hide_mobile: {
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      display: "none",
    },
  },
  hide_desktop: {
    [`@media (min-width: ${theme.vars.mobileWidth + 1}px)`]: {
      display: "none",
    },
  },
}))

export const Hide = ({ on, children, component: Component }) => {
  const classes = useStyles()

  return <Component className={classes[`hide_${on}`]}>{children}</Component>
}

Hide.propTypes = {
  on: PropTypes.oneOf(["mobile", "desktop"]).isRequired,
  component: PropTypes.string,
  children: PropTypes.node,
}

Hide.defaultProps = {
  component: "span",
}
