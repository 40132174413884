import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { createUseStyles } from "react-jss"
import { Testimonial } from "./testimonial"
import Flickity from "react-flickity-component"
import "./flickity.css"

const useStyle = createUseStyles({
  root: {},
})

export const Testimonials = () => {
  const classes = useStyle()
  const [verticalCenter, setVerticalCenter] = useState(false)

  useEffect(() => {
    setVerticalCenter(true)
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allTestimonialsCsv {
        nodes {
          name
          firma
          text
          url
          icon
          smallText
        }
      }
    }
  `)

  const testimonials = data.allTestimonialsCsv.nodes.map(t => ({
    ...t,
    smallText: t.smallText?.toLowerCase() === "true",
  }))

  return (
    <div className={classes.root}>
      <Flickity
        options={{
          // autoPlay: 7000,
          pageDots: false,
          wrapAround: true,
          pauseAutoPlayOnHover: true,
        }}
        static={true}
      >
        {testimonials.map((t, i) => (
          <Testimonial key={i} verticalCenter={verticalCenter} {...t} />
        ))}
      </Flickity>
    </div>
  )
}
