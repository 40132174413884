import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/wenke/wenke/src/components/layout.js";
import { Row } from "../components/row";
import { Gold } from '../components/gold';
import { Container } from '../components/container';
import { OwnCarousel } from '../components/carousel';
import { ContactForm } from '../components/contactForm';
import { Split } from '../components/split';
import { Spacer } from '../components/spacer';
import { Testimonials } from '../components/testimonials/testimonials';
import { FancyList } from '../components/fancyList';
import { Anchor } from '../components/anchor';
import { FancyButton } from '../components/fancyButton';
import { HrSpacer } from '../components/hrSpacer';
import { BackgroundImage } from '../components/backgroundImage';
import { Icons } from '../components/icons';
import { Center } from '../components/center';
import { Hide } from '../components/hide';
import { Year } from "../components/year";
import { State } from "../components/state";
import { ActivatedButton } from "../components/activatedButtons";
import { ShowWhenState } from "../components/showWhenState";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <State mdxType="State">
      <OwnCarousel mdxType="OwnCarousel" />
      <Spacer height={80} mdxType="Spacer" />
      <Container mdxType="Container">
        <Split space={100} mdxType="Split">
          <div>
            <h3><Gold mdxType="Gold">{`WENKE REICHERT`}</Gold></h3>
            <h1>{`ÜBER MICH`}</h1>
            <p>{`Fühlen Sie sich öfters gezwungen Aufgaben nach hinten zu schieben, weil vermeintlich dringenderes ansteht? Ob als Solopreneur oder als Geschäftsführer eines Unternehmens - den Fokus zu wahren und sich den Aufgaben zu stellen, die das Unternehmen tatsächlich wachsen lassen, ist nicht immer einfach. Doch es ist machbar!
Mit meiner Unterstützung erfahren Sie nicht nur Arbeitserleichterung, Sie entfalten ihr eigenes Potenzial und das ihrer Mitarbeiter. Sie wollen die Qualität Ihrer Arbeit verbessern? Ihre Work-Life-Balance ins Gleichgewicht bringen? Sich einfach mehr auf Ihr Kerngeschäft konzentrieren? Stattdessen füllt sich Ihre To-do-Liste unentwegt? Mit mir an Ihrer Seite schaffen Sie den Sprung ins nächste Level. Konzentrieren Sie sich auf das Wesentliche, indem Sie zeitraubende Aufgaben outsourcen oder Ihre Mitarbeiter dazu befähigen effizienter zu arbeiten.`}</p>
            <Spacer height={16} mdxType="Spacer" />
            <FancyButton component="a" extraSpace href="/#contact" mdxType="FancyButton">Kontakt aufnehmen</FancyButton>
          </div>
          <div>
            <Spacer mobileHeight={80} mdxType="Spacer" />
            <h3><Gold mdxType="Gold">{`ZEITSCHENKERIN`}</Gold>{` `}<Anchor name="output" mdxType="Anchor" /></h3>
            <h1>{`LEISTUNGEN`}</h1>
            <p>{`Sie können sich vorstellen unterstützt zu werden, aber Sie wissen nicht, womit Sie anfangen sollen und was Sie überhaupt delegieren können? Ich zeige Ihnen, wie Sie Tätigkeiten optimal auslagern, um endlich die Ziele Ihres Unternehmens und Ihre persönlichen Ziele zu erreichen. Es warten freie Kapazitäten, mehr Unabhängigkeit und mehr Spaß an der Arbeit auf Sie.
Ob Sie als Freiberufler oder als Chef einer Firma unterwegs sind - gerne berate ich Sie als Expertin für Zeitmanagement und Prozessoptimierungen. Dafür stelle ich bestehende interne Abläufe auf den Prüfstand, identifiziere Optimierungspotenziale und entwickle mit Ihnen und Ihren Mitarbeitern Lösungsansätze. Mein oberstes Ziel ist die Verbesserung der Wertschöpfungskette zu Ihrem Kunden. Hier erfahren Sie mehr:`}</p>
            <Spacer height={16} mdxType="Spacer" />
            <ActivatedButton active={'SELF'} component='a' href='/#output_more' setWidth={false} selected={false} mdxType="ActivatedButton">Für Selbstständige</ActivatedButton>
            <Spacer height={48} mdxType="Spacer" />
            <ActivatedButton active={'KMU'} component='a' href='/#output_more' setWidth={false} selected={false} mdxType="ActivatedButton">Für KMU</ActivatedButton>
          </div>
        </Split>
        <Spacer height={80} mdxType="Spacer" />
      </Container>
      <BackgroundImage image="Wenke_Business_6.jpg" position="30% top" mdxType="BackgroundImage">
        <Spacer height={80} mdxType="Spacer" />
        <Container mdxType="Container">
          <Split space={100} mdxType="Split">
            <div />
            <div>
              <h3><Gold mdxType="Gold">{`ÜBER MICH `}<Anchor name="about" mdxType="Anchor" /></Gold></h3>
              <h1>{`ICH SCHAFFE FREIRAUM`}</h1>
              <p>{`Für meine Kunden bin ich Ansprechpartnerin Nr. 1 - denn Sie können sich zu jeder Zeit und zu 100 % auf mich verlassen. Mit mehr als 25 Jahren Berufserfahrung und einer konsequenten wie disziplinierten Persönlichkeit gibt es wenig, was mich aus der Ruhe bringen kann.`}</p>
              <p>{`Auf meinem Arbeitsgebiet bin ich Expertin, die mit Qualität und zu Ende gedachten Ergebnissen überzeugt. Andererseits bin ich auch Vertraute, die dabei unterstützt, die eigenen Ziele auf die Straße zu bringen.`}</p>
              <Spacer height={64} mdxType="Spacer" />
              <h3>{`DAS BIN ICH FÜR SIE`}</h3>
              <Spacer height={12} mdxType="Spacer" />
              <Icons mdxType="Icons" />
            </div>
          </Split>
        </Container>
        <Spacer height={80} mdxType="Spacer" />
      </BackgroundImage>
      <Spacer height={80} mdxType="Spacer" />
      <Container mdxType="Container">
        <h3><Gold mdxType="Gold">LEISTUNGEN <Anchor name="output_more" mdxType="Anchor" /></Gold></h3>
        <Spacer height={24} mdxType="Spacer" />
        <Row mdxType="Row">
  <ActivatedButton active={'SELF'} component={'a'} setWidth={false} mdxType="ActivatedButton">Für Selbstständige</ActivatedButton>
  <ActivatedButton active={'KMU'} component={'a'} setWidth={false} mdxType="ActivatedButton">Für KMU</ActivatedButton>
        </Row>
        <Spacer height={8} mdxType="Spacer"></Spacer>
        <ShowWhenState state={'KMU'} mdxType="ShowWhenState">
          <Split space={64} mdxType="Split">
            <div>
              <p>{`Beobachten Sie Tag für Tag, dass Sie zu sehr in die Details einzelner operativer Projekte miteinbezogen sind? Vielleicht wissen Sie gar nicht, wer aus Ihrem Team diese Aufgaben übernehmen kann? Eine Realität, die sich ändern lässt.`}</p>
              <p>{`Ihnen ist genau bewusst, dass Sie als Leiter Ihres Unternehmens ausschließlich den Tätigkeiten nachgehen sollten, die für Ihr Unternehmen Wachstum und Umsatzsteigerung bedeuten.`}</p>
              <p>{`Als Zeitschenkerin kann ich Ihnen und Ihrem Team effizientere Arbeitsweisen näherbringen, sodass Ihre Mitarbeiter ihr volles Potenzial ausschöpfen und Sie sich wieder Ihrem Kerngeschäft widmen können.`}</p>
              <p>{`Lassen Sie uns in einem ersten Kennenlerngespräch den Ist-Zustand bestimmen und gemeinsam Ihre Ziele, Ideen und Visionen sammeln.`}</p>
              <p>{`Gerne werfe ich im Rahmen einer vertrauensvollen Zusammenarbeit vor Ort einen Blick in die interne Aufbau- und Ablauforganisation Ihres Unternehmens. So kann ich die passende Strategie für Sie und Ihre Mitarbeiter entwickeln, um Arbeitsprozesse im Hinblick auf ihre zeitlichen und finanziellen Ressourcen zu optimieren.`}</p>
            </div>
            <div>
              <p>{`Was kann das für Sie bedeuten?`}</p>
              <ul>
                <li parentName="ul">{`Ihr Team gewinnt an Arbeitseffektivität dazu`}</li>
                <li parentName="ul">{`Interne Abläufe werden so gestaltet, dass alle ihr volles Potenzial ausschöpfen`}</li>
                <li parentName="ul">{`Sie gewinnen an Zeit, um Ihr fachliches Talent dafür zu nutzen, Kunden zu Fans Ihres Unternehmens zu machen`}</li>
                <li parentName="ul">{`Umsatzsteigerung durch Ihren Fokus auf die Kernthemen`}</li>
                <li parentName="ul">{`Steigerung der Mitarbeitermotivation & -bindung durch Ihr Vertrauen in ihre Fähigkeiten beim Abgeben einzelner Aufgaben`}</li>
                <li parentName="ul">{`Wissen wird auf mehr Köpfe und Verantwortung auf mehr Schultern verteilt, wodurch eine neue Flexibilität entsteht`}</li>
              </ul>
              <p>{`Gemeinsam werden wir ein exklusives Beratungspaket schnüren. Damit Sie entlastet werden, nicht mehr länger als Ihre Mitarbeiter im Büro sind und auch endlich mal Zeit für sich finden.`}</p>
              <p>{`Nehmen Sie sich endlich genügend Zeit für die Dinge, mit denen Sie sich als CEO beschäftigen sollten, um das Wachstum Ihres Unternehmens zu sichern, ohne 24/7 zu arbeiten.`}</p>
              <p>{`Buchen Sie sich noch heute ein erstes Kennenlerngespräch, in dem wir gemeinsam herausfinden, ob und wie ich Sie unterstützen kann!`}</p>
            </div>
          </Split>
        </ShowWhenState>
        <ShowWhenState state={'SELF'} mdxType="ShowWhenState">
          <Split space={100} mdxType="Split">
            <div>
              <p>{`Sie wissen am allerbesten, was Sie und Ihre beruflichen Ziele zurückhält - weshalb die 24 Stunden am Tag nicht ausreichend sind.`}</p>
              <p>{`Als ZEITschenkerin kann ich Sie so weit entlasten, dass Ihre Tage wieder länger und Ihre Listen wieder kürzer werden.`}</p>
              <p>{`All die Dinge, die Sie outsourcen müssen, um selbst wieder mehr Raum zu genießen und diesen produktiv nutzen zu können, übernehme ich gerne für Sie.`}</p>
              <p>{`Dazu gehört eine Vielzahl von To-dos hinter den Kulissen, die neben ihrem Kerngeschäft anfallen und erledigt werden wollen.`}</p>
              <p>{`Um Ihre Arbeitsprozesse zu optimieren und effizienter zu gestalten, werden wir gemeinsam in einem ersten Analyse-Gespräch den Ist-Zustand bestimmen und herausfinden, wo es Sinn macht zu delegieren.`}</p>
              <p>{`Gerne schaue ich mir dafür mit Ihnen oder / und Ihren Mitarbeitern die internen Arbeitsabläufe auch vor Ort genau an und entwickle Strategien, damit Sie die Qualität und die Geschwindigkeit Ihrer Arbeit auf ein höheres Level bringen und mehr Aufträge annehmen können.`}</p>
            </div>
            <div>
              <p>{`Für Sie bedeutet das, dass Sie lästige und zeitaufwendige Tätigkeiten an mich abgeben, wir gemeinsam Ihren Fokus schärfen und Sie endlich wieder mehr Zeit für Ihr Kerngeschäft und Ihr Privatleben haben. Mit meiner Unterstützung lenken administrative Aufgaben Sie nicht mehr ab, sodass Sie leichter in Ihrem Flow bleiben und Ihr fachliches Know-how nutzen können, um Ihre Kunden zu begeistern!`}</p>
              <p>{`Aus meinem vielfältigen Serviceangebot werden wir gemeinsam Ihr exklusives Dienstleistungspaket schnüren.`}</p>
              <p>{`Ich unterstütze Sie gerne in vielerlei Hinsicht - im Projektmanagement, im Marketing, in der Zuarbeit von Backoffice-Tätigkeiten oder bei ganz individuellen Herausforderungen.`}</p>
              <p>{`Buchen Sie sich noch heute ein Gespräch mit mir, damit wir zusammen FREIraum für Ihr Wachstum schaffen!`}</p>
            </div>
          </Split>
        </ShowWhenState>
      </Container>
      <Spacer height={80} mdxType="Spacer" />
      <BackgroundImage image="Wenke_Business_2.jpg" position="55% 33%" mdxType="BackgroundImage">
        <Spacer height={240} mobileHeight={80} mdxType="Spacer" />
        <Container mdxType="Container">
          <Split mdxType="Split">
            <div>
              <h3><Gold mdxType="Gold">{`DAS IST GENAU DAS, WAS SIE BRAUCHEN?`}</Gold></h3>
              <h1>{`DANN MELDEN SIE SICH.`}</h1>
              <Spacer height={32} mdxType="Spacer" />
              <FancyButton component='a' extraSpace href="/#contact" mdxType="FancyButton">Kontakt Aufnehmen</FancyButton>
            </div>
            <div />
          </Split>
        </Container>
        <Spacer height={240} mobileHeight={80} mdxType="Spacer" />
      </BackgroundImage>
      <Container mdxType="Container">
        <Spacer height={80} mdxType="Spacer" />
        <Split space={100} mdxType="Split">
          <div>
            <h3><Gold mdxType="Gold">{`ZEITSCHENKERIN`}</Gold></h3>
            <h1>{`DAS MACHT MICH AUS`}</h1>
            <p>{`Verlassen Sie sich auf mich, ich mache mir Ihre Projekte zu eigen und führe Sie zum Ziel. Dabei bin ich termintreu, kreativ, stets lösungsorientiert sowie verlässlich. Ich achte auf Details, bin in der Lage mich einerseits in Situationen hineinzuversetzen und andererseits über den Tellerrand hinauszuschauen und verliere dabei nicht den wirtschaftlichen Überblick. Um das alles zu gewährleisten, versuche ich immer auf dem neuesten methodischen Wissensstand zu sein und bilde mich stets weiter.`}</p>
            <p>{`Das überzeugt Sie? Dann lassen Sie uns sprechen!`}</p>
            <Spacer height={16} mdxType="Spacer" />
            <FancyButton component='a' extraSpace href="/#contact" mdxType="FancyButton">Kontakt Aufnehmen</FancyButton>
          </div>
          <div>
            <Hide on={'mobile'} mdxType="Hide">
              <h3>{` `}</h3>
            </Hide>
            <Spacer height={16} mdxType="Spacer" />
            <h3>{`BERUFLICHER WERDEGANG`}</h3>
            <FancyList mdxType="FancyList">
              <p>{`  Ausbildung zur Werbekauffrau`}</p>
              <p>{`  9 Jahre Sachbearbeiterin Branche Einkauf, Marketing und Arbeitsvorbereitung`}</p>
              <p>{`  5 Jahre Abteilungsleiterin Disposition Branche Immobilien`}</p>
              <p>{`  5 Jahre Projektleitung Branche Marketing`}</p>
  <p><Year mdxType="Year" /> Jahre Zeitschenkerin</p>
            </FancyList>
          </div>
          <div />
        </Split>
      </Container>
      <Spacer height={80} mdxType="Spacer" />
      <BackgroundImage image="Wenke_103_edit.jpg" position="34% top" mdxType="BackgroundImage">
        <Spacer height={230} mobileHeight={160} mdxType="Spacer" />
        <Container mdxType="Container">
          <Split width={40} mdxType="Split">
            <div />
            <Center text mdxType="Center">
              <div>
                <Hide on='mobile' mdxType="Hide">
                  <h6>{`MIR IST ES AUSGESPROCHEN WICHTIG,`}<br />{`DASS SIE ALS `}<Gold mdxType="Gold"><span style={{
                        fontSize: 41
                      }}>{`KUNDE`}</span></Gold>{` NACH AUSSEN`}<br />{`BESTMÖGLICH WAHRGENOMMEN WERDEN.`}</h6>
                  <h6>{`DESHALB IST ES MEINE PASSION,`}<br />{`SIE UND IHR UNTERNEHMEN`}<br />{` IMMER `}<Gold mdxType="Gold"><span style={{
                        fontSize: 41
                      }}>{`PERFEKT`}</span></Gold>{` ZU REPRÄSENTIEREN.`}</h6>
                </Hide>  
                <Hide on='desktop' mdxType="Hide">
                  <h6>{`MIR IST ES `}<br />{`AUSGESPROCHEN `}<br />{`WICHTIG,`}<br />{`DASS SIE ALS `}<br /><Gold mdxType="Gold"><span style={{
                        fontSize: 41
                      }}>{`KUNDE`}<br /></span></Gold>{` NACH AUSSEN`}<br />{`BESTMÖGLICH WAHRGENOMMEN `}<br />{`WERDEN.`}</h6>
                  <h6>{`DESHALB`}<br />{`IST ES MEINE`}<br />{`PASSION,`}<br />{`SIE UND IHR UNTERNEHMEN IMMER `}<br /><Gold mdxType="Gold"><span style={{
                        fontSize: 41
                      }}>{`PERFEKT`}</span></Gold><br />{` ZU REPRÄSENTIEREN.`}</h6>
                </Hide>
              </div>
            </Center>
          </Split>
        </Container>
        <Spacer height={230} mobileHeight={160} mdxType="Spacer" />
      </BackgroundImage>
      <Spacer height={80} mdxType="Spacer" />
      <Container mdxType="Container">
        <Center mdxType="Center">
          <div>
            <h3><Gold mdxType="Gold">{`REFERENZEN `}<Anchor name="reference" mdxType="Anchor" /></Gold></h3>
            <h1>{`DAS SAGEN MEINE KUNDEN ÜBER`}<br />{`MICH `}<Hide on='mobile' mdxType="Hide">{`UND DIE ZUSAMMENARBEIT`}</Hide></h1>
          </div>
        </Center>
        <Spacer height={80} mdxType="Spacer" />
        <Testimonials mdxType="Testimonials" />
        <Spacer height={80} mdxType="Spacer" />
      </Container>
      <HrSpacer mdxType="HrSpacer" />
      <Container mdxType="Container">
        <Spacer height={80} mdxType="Spacer" />
        <h3><Gold mdxType="Gold">{`SCHREIBEN SIE MIR`}</Gold>{` `}<Anchor name="contact" mdxType="Anchor" /></h3>
        <h1>{`ICH FREUE MICH AUF SIE!`}</h1>
        <Spacer height={80} mdxType="Spacer" />
        <Split width={60} mdxType="Split">
          <ContactForm mdxType="ContactForm" />
          <div>
            <Spacer mobileHeight={80} mdxType="Spacer" />
            <h2 style={{
              marginTop: 0
            }}>SIE ERREICHEN MICH AUCH HIER:</h2>
            <p>{`Wenke Reichert`}<br parentName="p"></br>{`
`}{`Zeitschenkerin`}<br parentName="p"></br>{`
`}{`Im Willstein 33`}<br parentName="p"></br>{`
`}{`57319 Bad Berleburg`}<br parentName="p"></br>{`
`}{`Telefon: `}<a parentName="p" {...{
                "href": "tel:+491635666111"
              }}>{`+49 (0) 163 566 61 11`}</a><br parentName="p"></br>{`
`}{`E-Mail: `}<a parentName="p" {...{
                "href": "mailto:info@wenke-reichert.de"
              }}>{`info@wenke-reichert.de`}</a></p>
          </div>
        </Split>
      </Container>
      <Spacer height={160} mobileHeight={120} mdxType="Spacer" />
    </State>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      