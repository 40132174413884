import React from "react"
import { globalStateContext } from "./state"
import { FancyButton } from "./fancyButton"

export const ActivatedButton = ({ active, children, ...props }) => {
  const { selectedOutput, setSelectedOutput } = React.useContext(
    globalStateContext
  )
  return (
    <FancyButton
      onPress={() => setSelectedOutput(active)}
      selected={selectedOutput === active}
      {...props}
    >
      {children}
    </FancyButton>
  )
}
