import React from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"

const useStyles = createUseStyles(theme => ({
  spacer: {
    height: props => props.height,
    [`@media (min-width: ${theme.vars.mobileWidth + 1}px)`]: {
      height: props => props.desktopHeight,
    },
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      height: props => props.mobileHeight,
    },
  },
}))

export const Spacer = props => {
  const classes = useStyles(props)

  return <div className={classes.spacer} />
}

Spacer.propTypes = {
  height: PropTypes.number,
  desktopHeight: PropTypes.number,
  mobileHeight: PropTypes.number,
}
